// React
import React, { useMemo } from "react";
import PropTypes from "prop-types";
// Framework
import {
  makeStyles,
  alpha,
  Box,
  Grid,
  Button,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { classnames } from "ui/classnames";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: ({ color, bgcolor }) => ({
    width: "100%",
    height: "100%",
    minWidth: "auto",
    minHeight: "auto",
    padding: 0,
    margin: 0,
    borderRadius: theme.radius.normal,
    boxShadow: theme.shadows[0],
    transition: theme.transitions.create("background"),
    fontSize: theme.typography.h2.fontSize,
    border: theme.palette.border.prominent,
    borderColor: alpha(color, 0.1),
    backgroundColor: bgcolor ?? alpha(color, 0.15),
    color,
    "&:disabled": {
      color,
      backgroundColor: bgcolor ?? alpha(color, 0.15),
    },
    "&:hover": {
      backgroundColor: bgcolor ?? alpha(color, 0.25),
    },
  }),
  selected: ({ color, bgcolor }) => ({
    backgroundColor: bgcolor ?? color,
    color: theme.palette.common.white,
    "&:disabled": {
      color: theme.palette.common.black,
      backgroundColor: bgcolor ?? color,
    },
    "&:hover": {
      backgroundColor: bgcolor ?? color,
    },
  }),
  disabled: {
    "&:disabled": {
      color: `${theme.palette.grey[300]} !important`,
      backgroundColor: `${theme.palette.grey[100]} !important`,
    },
  },
  title: {
    position: "absolute",
    transform: "translateX(-50%)",
    left: "50%",
    bottom: 0,
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const FlatIcon = ({
  component = "button",
  size = "default",
  title,
  tooltip,
  icon,
  color = "#000",
  bgcolor,
  disabled,
  selected,
  onClick,
}) => {
  // Styles
  const classes = useStyles({ color, bgcolor });
  // Memo
  const sizes = useMemo(
    () => ({
      large: { width: 80, height: 80 },
      default: { width: 60, height: 60 },
      medium: { width: 48, height: 48 },
      small: { width: 40, height: 40 },
    }),
    []
  );
  // Render
  return (
    <Tooltip placement="bottom" title={tooltip ?? ""}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        {icon && (
          <Grid item>
            <Box width={sizes[size]?.width} height={sizes[size]?.height}>
              <Button
                disableRipple
                component={component}
                classes={{
                  root: classnames(classes.root, {
                    [classes.disabled]: disabled,
                    [classes.selected]: selected,
                  }),
                }}
                variant="contained"
                disabled={disabled || !onClick}
                onClick={onClick}
              >
                {icon}
              </Button>
            </Box>
          </Grid>
        )}
        {title && (
          <Grid item>
            <Box position="relative" minHeight={20} color={color}>
              <Typography
                noWrap
                variant="caption"
                align="center"
                display="inline"
                color="textSecondary"
                className={classes.title}
              >
                {title}
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </Tooltip>
  );
};

FlatIcon.propTypes = {
  component: PropTypes.string,
  size: PropTypes.oneOf(["default", "small", "medium", "large"]),
  title: PropTypes.string,
  tooltip: PropTypes.string,
  icon: PropTypes.object,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default FlatIcon;
