// React
import React, {
  Children,
  cloneElement,
  isValidElement,
  useState,
  useMemo,
  useCallback,
} from "react";
import PropTypes from "prop-types";
// Helpers
import { isEmpty, compact } from "@mefisto/utils";
// Framework
import { makeStyles, Box, Popover, MenuList, IconButton } from "ui";
import { MoreHorizRounded as MoreIcon } from "icon/material";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  button: {
    height: 40,
    color: theme.palette.action.active,
    border: theme.palette.border.prominent,
    borderRadius: theme.radius.small,
    backgroundColor: theme.palette.background.prominent.main,
    transition: theme.transitions.create(["color", "border", "background"]),
    "&:hover": {
      border: theme.palette.border.prominent,
      backgroundColor: theme.palette.background.prominent.hover,
    },
    "&:focus": {
      border: theme.palette.border.prominent,
      backgroundColor: theme.palette.background.prominent.hover,
    },
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const CoverOptions = ({ options = [] }) => {
  // Styles
  const classes = useStyles();
  // State
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  // Memo
  const hasChildren = useMemo(() => {
    return !isEmpty(compact(options));
  }, [options]);
  // Handlers
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);
  const handleButtonClick = useCallback(({ currentTarget }) => {
    setAnchorEl(currentTarget);
    setOpen(true);
  }, []);
  // Render
  return (
    <>
      {hasChildren && (
        <Box>
          <IconButton
            value="more"
            className={classes.button}
            onClick={handleButtonClick}
          >
            <MoreIcon />
          </IconButton>
          <Popover
            keepMounted
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            offset={{ top: 0.5 }}
            onClose={handleClose}
          >
            <MenuList dense>
              {Children.map(options, (option) => {
                return (
                  isValidElement(option) &&
                  cloneElement(option, {
                    onClose: handleClose,
                  })
                );
              })}
            </MenuList>
          </Popover>
        </Box>
      )}
    </>
  );
};

CoverOptions.propTypes = {
  options: PropTypes.arrayOf(PropTypes.node),
};

export default CoverOptions;
