// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { Spinner, Box, Typography, Cloak, makeStyles } from "ui/components";
import { Section } from "ui/section";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: ({ width, height, minHeight, disableMargin, disablePadding }) => ({
    position: "relative",
    backgroundColor: theme.palette.background.underlying,
    width: width ?? "100%",
    height,
    minHeight,
    borderRadius: theme.radius.normal,
    border: theme.palette.border.default,
    marginTop: theme.spacing(disableMargin ? 0 : 3),
    padding: theme.spacing(
      disablePadding ? 0 : 2,
      disablePadding ? 0 : 3,
      disablePadding ? 0 : 3
    ),
  }),
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const TileInfoArea = ({
  context = "infoArea",
  value,
  title,
  disableMargin,
  disablePadding,
  width,
  height,
  minHeight,
  loading,
  titleProps,
  children,
}) => {
  // Styles
  const classes = useStyles({
    disableMargin,
    disablePadding,
    width,
    height,
    minHeight,
  });
  // Render
  return (
    <Section context={context} value={value}>
      <div className={classes.root}>
        {loading && <Spinner size="small" position="center" />}
        <Cloak enabled={loading}>
          {title && (
            <Box mb={2}>
              <Typography variant="subtitle2" {...titleProps}>
                {title}
              </Typography>
            </Box>
          )}
          {children}
        </Cloak>
      </div>
    </Section>
  );
};

TileInfoArea.propTypes = {
  context: PropTypes.string,
  value: PropTypes.string,
  title: PropTypes.string,
  disableMargin: PropTypes.bool,
  disablePadding: PropTypes.bool,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  titleProps: PropTypes.any,
  children: PropTypes.any,
};

export default TileInfoArea;
