// React
import React, { memo, useRef, useState, useCallback } from "react";
import PropTypes from "prop-types";
// Framework
import { useAction } from "hooks";
import { useTranslate } from "localization";
import { useEntityRead } from "model/hooks";
import { EntityPropType } from "model/utils";
import { makeStyles, Section, Fade, Button } from "ui";
import { Image } from "image";
import { PhotoCameraTwoTone as UpdateIcon } from "icon/material";
// Components
import ModelCoverImageDialog from "./components/ModelCoverImageDialog";

////////////////////////////////////////////////////
/// Styles
////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 320,
    overflow: "hidden",
    borderRadius: `${theme.radius.large}px ${theme.radius.large}px 0 0`,
    border: `1px solid ${theme.palette.divider}`,
    background: theme.palette.background.underlying,
    transform: "translateZ(0)",
  },
  button: {
    position: "absolute",
    right: theme.spacing(3),
    top: 260,
    [theme.breakpoints.down("xs")]: {
      top: 24,
    },
  },
}));

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const ModelCoverImage = ({
  context = "coverImage",
  value,
  actions,
  entity: Entity,
  input,
  resources,
  languages,
  tags,
  fetchPolicy,
  fileName = "coverImage",
}) => {
  // Framework
  const { t } = useTranslate();
  const { a } = useAction(actions);
  // Styles
  const classes = useStyles();
  // State
  const [showUpdateButton, setShowUpdateButton] = useState(false);
  // Model
  const { data } = useEntityRead(Entity, {
    input,
    resources,
    fetchPolicy,
    languages,
    tags,
  });
  // Ref
  const dialogRef = useRef(null);
  // Callbacks
  const handleMouseOver = useCallback(() => {
    setShowUpdateButton(a("update"));
  }, [a]);
  const handleMouseOut = useCallback(() => {
    setShowUpdateButton(false);
  }, []);
  const handleUpdate = useCallback(() => {
    dialogRef.current.open("update", {
      input,
      resources,
      overrides: {
        [fileName]: null,
      },
      silent: true,
      optimisticUI: false,
    });
  }, [input, resources, fileName]);
  // Render
  return (
    <Section context={context} value={value}>
      <ModelCoverImageDialog
        ref={dialogRef}
        entity={Entity}
        fileName={fileName}
      />
      <div
        className={classes.root}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseOut}
      >
        {data[fileName] && <Image image={data[fileName]} height={320} />}
        <Fade in={showUpdateButton} unmountOnExit>
          <Button
            value="update"
            variant="contained"
            size="small"
            startIcon={<UpdateIcon color="inherit" />}
            className={classes.button}
            onClick={handleUpdate}
          >
            {data[fileName]
              ? t("core:model.coverImage.button.update")
              : t("core:model.coverImage.button.upload")}
          </Button>
        </Fade>
      </div>
    </Section>
  );
};

ModelCoverImage.propTypes = {
  context: PropTypes.string,
  value: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.oneOf(["update", false])),
  entity: EntityPropType,
  input: PropTypes.object,
  resources: PropTypes.object,
  languages: PropTypes.array,
  tags: PropTypes.any,
  fetchPolicy: PropTypes.string,
  fileName: PropTypes.string,
};

export default memo(ModelCoverImage);
